import {
  collection,
  deleteDoc,
  doc,
  enableIndexedDbPersistence,
  FirestoreDataConverter,
  getDoc,
  getFirestore,
  QueryDocumentSnapshot,
  setDoc,
  SnapshotOptions,
  Timestamp,
} from 'firebase/firestore'
import { defineStore } from 'pinia'
import { bind } from 'pinia-firestore'
import app from '@/firebase'
import { SplashScreen } from '@capacitor/splash-screen'
import { ContentBlock } from '@/components/ContentBlocks.vue'

const db = getFirestore(app)
enableIndexedDbPersistence(db)

export class EventType {
  __path?: string
  displayName = ''
  bannerUrl = ''
  places: { [key: string]: { color: string, label: string } } = {}
}

export class EventScheduleItem {
  __id?: string = undefined
  title = ''
  description = ''
  blocks: ContentBlock[] = []
  place: string | null = null
  time: {
    start: Date
    originalStart?: Date
    duration?: number
  } = { start: new Date() }
}

const EventScheduleConverter: FirestoreDataConverter<EventScheduleItem> = {
  toFirestore(item: EventScheduleItem): any {
    const data: any = { ...item }
    delete data.__id
    data.blocks = item.blocks.map(block => ({ ...block }))
    data.time.start = Timestamp.fromDate(item.time.start)
    if (item.time.originalStart) data.time.originalStart = Timestamp.fromDate(item.time.originalStart)
    if (item.time.duration) data.time.duration = item.time.duration
    return data
  },

  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): EventScheduleItem {
    const data = snapshot.data(options)
    data.time.start = data.time.start.toDate()
    if (data.time.originalStart) data.time.originalStart = data.time.originalStart.toDate()
    return Object.assign(new EventScheduleItem(), data)
  }
}

export class EventAttendee {
  __id?: string = undefined
  photoUrl = ''
  name = ''
  about = ''
  doing = ''
  interests = ''
  blocks: ContentBlock[] = []
  companyId?: string = undefined
  present = false
}

const EventAttendeesConverter: FirestoreDataConverter<EventAttendee> = {
  toFirestore(item: EventAttendee): any {
    const data: any = { ...item }
    delete data.__id
    if (!data.companyId) delete data.companyId
    data.blocks = item.blocks.map(block => ({ ...block }))
    return data
  },

  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): EventAttendee {
    const data = snapshot.data(options)
    return Object.assign(new EventAttendee(), data)
  }
}

export class EventCompany {
  __id?: string = undefined
  logoUrl = ''
  name = ''
  jobs: {
    title: '',
    linkUrl: ''
  }[] = []
}

const EventCompanyConverter: FirestoreDataConverter<EventCompany> = {
  toFirestore(item: EventCompany): any {
    const data: any = { ...item }
    delete data.__id
    return data
  },

  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): EventCompany {
    const data = snapshot.data(options)
    return Object.assign(new EventCompany(), data)
  }
}

const eventId = 'ruxc-2022'
const eventRef = doc(db, 'events', eventId)
const scheduleRef = collection(db, 'events', eventId, 'schedule').withConverter(EventScheduleConverter)
const attendeesRef = collection(db, 'events', eventId, 'attendees').withConverter(EventAttendeesConverter)
const companiesRef = collection(db, 'events', eventId, 'companies').withConverter(EventCompanyConverter)

export const useEventStore = defineStore({
  id: 'event',
  state: () => ({
    event: {} as EventType,
    schedule: [] as EventScheduleItem[],
    attendees: [] as EventAttendee[],
    companies: [] as EventCompany[],
  }),
  actions: {
    async init() {
      await SplashScreen.show({ autoHide: false })
      await Promise.all([
        bind(this, 'event', eventRef),
        bind(this, 'schedule', scheduleRef),
        bind(this, 'attendees', attendeesRef),
        bind(this, 'companies', companiesRef)
      ])
      await SplashScreen.hide()
    },
    async getScheduleItem(id: string): Promise<EventScheduleItem | undefined> {
      const item = (await getDoc(doc(scheduleRef, id))).data()
      if (item)
        item.__id = id
      return item
    },
    async setScheduleItem(item: EventScheduleItem) {
      const ref = item.__id ? doc(scheduleRef, item.__id) : doc(scheduleRef)
      await setDoc(ref, item)
      return ref
    },
    async removeScheduleItem(id: string) {
      return deleteDoc(doc(scheduleRef, id))
    },
    async getAttendee(id: string): Promise<EventAttendee | undefined> {
      const item = (await getDoc(doc(attendeesRef, id))).data()
      if (item)
        item.__id = id
      return item
    },
    async setAttendee(item: EventAttendee) {
      const ref = item.__id ? doc(attendeesRef, item.__id) : doc(attendeesRef)
      await setDoc(ref, item)
      return ref
    },
    async removeAttendee(id: string) {
      return deleteDoc(doc(attendeesRef, id))
    },
    async getCompany(id: string): Promise<EventCompany | undefined> {
      const item = (await getDoc(doc(companiesRef, id))).data()
      if (item)
        item.__id = id
      return item
    },
    async setCompany(item: EventCompany) {
      const ref = item.__id ? doc(companiesRef, item.__id) : doc(companiesRef)
      await setDoc(ref, item)
      return ref
    },
    async removeCompany(id: string) {
      return deleteDoc(doc(companiesRef, id))
    },
  },
})
