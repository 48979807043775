import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { IonicVue } from '@ionic/vue'
import { createPinia } from 'pinia'
/* import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { App as CapApp, URLOpenListenerEvent } from '@capacitor/app'
import axios from 'axios' */

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(createPinia())

router.isReady().then(() => {
  app.mount('#app')
})

/* CapacitorUpdater.notifyAppReady()
let versionAvailable = ''
CapApp.addListener('appStateChange', async ({ isActive }) => {
  if (isActive) {
    const { data: { build_datetime } } = await axios.get('https://webapp.vlakfest.cz/update.json')
    console.log('available version', build_datetime)
    if (build_datetime > process.env.VUE_APP_BUILD_DATETIME) {
      console.log('downloading update')
      versionAvailable = (await CapacitorUpdater.download({
        url: 'https://webapp.vlakfest.cz/update.zip',
      })).version
    }
  }
  if (!isActive && versionAvailable !== '') {
    await CapacitorUpdater.set({ version: versionAvailable })
  }
})

CapApp.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
  const slug = event.url.split('vlakfest.cz').pop()

  // We only push to the route if there is a slug present
  if (slug) {
    router.push(slug)
  }
}) */
