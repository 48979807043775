import { createPinia, defineStore } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

class authClaims {
  isAdmin = true
}

export const useAuthStore = defineStore('auth', {
  state: () => {
    const loaded = localStorage.getItem('authState')
    if (loaded) {
      return JSON.parse(loaded)
    }
    return {
      claims: new authClaims()
    }
  },
  actions: {
    setLocalClaims (claims: authClaims) {
      this.claims = claims
      localStorage.setItem('authState', JSON.stringify(this.$state))
    }
  }
})
